.padding-top-10 {
    padding-top: $padding-medium;
}

.push-down-10 {
    position: relative;
    top: 30px;
}

.vertical-align-middle {
    vertical-align: middle;
}

.vertical-align-top {
    vertical-align: top;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.text-align-left {
    text-align: left;
}