/* Slider START */

/* slider link design START */
.ngl-vt-slider .info a {
    text-decoration: none;
}
/* slider link design END */

/* center additional information text START */
.view-type-slider_1 .info, .view-type-slider_2 .info {
    position: absolute;
    top: 48%;
    width: 100%;
    text-align: center;
}
/* center additional information text END */


.gallery-top {
    height: 80%;
    width: 100%;
}
.gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
    width: 25%;
    height: auto;
    opacity: 0.4;
    max-height: 75px;
}
.gallery-thumbs .swiper-slide-active {
    opacity: 1;
}

.carousel .carousel-cell {
    outline: 0;
    overflow: hidden;
}

.carousel-thumbs .carousel-nav {
    margin-top: 0 !important;
}

.carousel .flickity-page-dots {
    bottom: 0;
}

.carousel .flickity-page-dots {
    margin-bottom: 20px;
}


/* Slider END */
