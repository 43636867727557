@import '../../../../../../web/bundles/ixproductconnector/vendor/bootstrap/css/bootstrap.min.css';
@import '../../../../../../web/bundles/ixproductconnector/vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
//@import '../../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
//@import '../../../../../../node_modules/bootstrap';
@import '../../../../../../node_modules/ion-rangeslider/css/ion.rangeSlider.min.css';

@import '../../../../../../web/bundles/ixproductconnector/vendor/bootstrap/css/bootstrap-glyphicons.css';
@import '../../../../../../web/bundles/ixproductconnector/css/productfinder';
@import '../../../../../../web/bundles/ixwishlist/sass/wishlist';
@import '../../../../../../web/bundles/ixsearchautosuggest/sass/autosuggest';
@import '../../../../../../web/bundles/productcomparison/css/comparison.scss';

//@import './vendor/cookieconsent.min.css';
@import './old_theme/main';
@import './sol/main';
@import './helpers/main';
@import './elements/main';
@import './layout/main';
@import './views/main';
@import '../../../../../../web/bundles/ixsearchdemo/sass/downloads';
//@import '../../../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
//@import '../../../../../../node_modules/@fortawesome/fontawesome-free/scss/solid';
//@import '../../../../../../node_modules/@fortawesome/fontawesome-free/scss/regular';
//@import '../../../../../../node_modules/@fortawesome/fontawesome-free/scss/brands';

@import '../../../../../../node_modules/@fortawesome/fontawesome-pro/css/all.css';
@import 'fixtures';
