.embed-container {
    position: relative;
    padding-bottom: 56.25%; /* ratio 16x9 */
    overflow: hidden;
    width: 100%;
    height: auto;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    /* ratio 4x3 */
    &.ratio4x3 {
        padding-bottom: 75%;
    }
}
