/* grid-teaser style START*/

/* design of the category field on the teasers START */
.view-type-teaser_1 .teaser-category, .view-type-teaser_2 .teaser-category {
    max-width: 40%;
    position: absolute;
    text-align: center;
    font-weight: bold;
    background-color: $ligthGreyColor;
    padding: 1px;
    left: 20px;
    hyphens: auto;
    top: 5px;
    opacity: 0.8;
    color: $ixTuerkis;
}
/* design of the category field on the teasers END */

.ngl-vt-grid .row, .category-children .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    clear: both;
}

/* make the equal-height class (.ngl-vt-grid .row) possible for safari browser START */
.ngl-vt-grid .row:before, .category-children .row:before,
.ngl-vt-grid .row:after, .category-children .row:after {
    width: 0;
}
/* make the equal-height class (.ngl-vt-grid .row) possible for safari browser END */

.col-lg-6.teaser_2, .col-lg-4.teaser_2, .col-lg-3.teaser_2 {
    margin-bottom: 200px;
}

.col-lg-6.teaser_1, .col-lg-4.teaser_1, .col-lg-3.teaser_1 {
    margin-bottom: 100px;
}

@media (max-width: 767px) {
    .col-lg-6.teaser_2, .col-lg-4.teaser_2, .col-lg-3.teaser_2, .col-lg-6.teaser_1, .col-lg-4.teaser_1, .col-lg-3.teaser_1 {
        margin-bottom: unset;
    }
}

.view-type-teaser_2 .date, .view-type-teaser_1 .date {
    max-width: 40%;
    position: absolute;
    top: 5px;
    text-align: right;
    font-weight: bold;
    background-color: $ligthGreyColor;
    color: $ixTuerkis;
    padding: 1px;
    right: 20px;
    hyphens: auto;
    opacity: 0.8;
}

.ngl-list .view-type-teaser_1 .info, .ngl-list .view-type-teaser_2 .info, .category-children .view-type-teaser_1 .info, .category-children .view-type-teaser_2 .info {
    padding: 5px;
    height: 200px;
    position: absolute;
    overflow: hidden;
    bottom: 0;
    bottom: -150px;
}

@media (max-width: 767px) {
    .ngl-vt-grid .row, .category-children .info {
        display: unset;
        display: unset;
        -webkit-flex-wrap: unset;
        display: unset;
        flex-wrap: unset;
    }

    .ngl-list .view-type-teaser_1 .info, .ngl-list .view-type-teaser_2 .info, .category-children .view-type-teaser_1 .info, .category-children .view-type-teaser_2 .info {
        height: unset;
        position: unset;
        bottom: unset;
    }
    .view-type-teaser_1, .view-type-teaser_2, .view-type-teaser_3, .col-lg-4 .view-type-teaser_1, .col-lg-4 .view-type-teaser_2, .col-lg-4 .view-type-teaser_2 {
        height: unset !important;
    }


}

/* link design START */
.teaser_3 a:hover, .teaser_2 a:hover, .teaser_1 a:hover {
    text-decoration: none;
}
/* link design END */

/* make non linkable objects look the same linked objects when they are displayed next to each other (e.g. grid teaser)  */
.look-as-linked .info .title {
    color: $linkColor;
}
/* link design END */

/* cuts the text in a teaser, if too long START */
.ngl-list .short p, .category-children .short p {
    /* hide text if it more than N lines  */
    overflow: hidden;
    /* for set '...' in absolute position */
    position: relative;
    /* use this value to count block height */
    line-height: 1.2em;
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: 7.2em;
    /* fix problem when last visible word doesn't adjoin right side  */
    text-align: justify;
    /* place for '...' */
    /*margin-right: -1em;*/
    padding-right: 1em;
}

/* create the ... */
.ngl-list .short p:before, .category-children .short p:before {
    /* points in the end */
    content: '...';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
}
/* hide ... if we have text, which is less than or equal to max lines */
.ngl-list .short p:after, .category-children .short p:after {
    /* points in the end */
    content: '';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    /* set width and height */
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    /* bg color = bg color under block */
    background: white;
}
/* cuts the text in a teaser, if too long END */

/* grid-teaser style END */
