$zoom-scale: 2;

.carousel {
    position: relative;
    outline: none;
    overflow: hidden;

    &-main {
        .is-selected {
            img {
                transform-origin: 50% 50%;
                transition: transform 300ms;
                display: block;
            }
            &:hover img {
                transform: scale($zoom-scale);
            }
        }
    }

    .flickity-viewport {
        transition: height 0.2s;
    }

    .carousel-cell {
        outline: 0;
        overflow: hidden;
        width: 100%;

        img {
            display: block;
            width: 100%;
        }
    }

    &:hover {
        cursor: grab;
    }

    /* teaser */
    .teaser {
        padding: 0;
    }

    /* teaser info */
    .info {
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%,-50%);
        background-color: $whiteColor;
        padding: 5px 10px;
        color: $ixTuerkis;
        opacity: 0.75;
        height: auto;
    }

    .intro {
        position: absolute;
        bottom: 0%;
        left: 50%;
        transform: translateX(-50%);
        background-color: $whiteColor;
        padding: 5px 10px 5px 10px;
        p {
            color: $ixBlue;
        }
    }

    @media (max-width: 991px) {
        .intro {
            display: none;
        }
    }

    /* prev and next buttons */
    .flickity-button {
        background: transparent;
        position: absolute;
        z-index: 100;
        top: 50%;
        transform: translateY(-50%);
        border: none;

        &:hover {
            cursor: pointer;
        }
    }

    .flickity-prev-next-button {
        width: 50px;
    }

    .flickity-prev-next-button.previous {
        left: 2%;
    }

    .flickity-prev-next-button.next {
        right: 2%;
    }

    .flickity-button-icon {
        fill: $mainColor;
    }

    .flickity-button:disabled {
        display: none;
    }

    /* pagination */
    .flickity-page-dots {
        position: absolute;
        bottom: -3%;
        left: 50%;
        transform: translateX(-50%);
        padding-left: 0;

        @media screen and (max-width: $break-orig-lg ) {
            bottom: -5%;
        }
    }

    .flickity-page-dots .dot {
        width: 15px;
        height: 15px;
        opacity: 1;
        background: transparent;
        border: 2px solid $mainColor;
        list-style:none;
        display: inline-block;
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;
    }

    .flickity-page-dots .dot.is-selected {
        background: $mainColor;
    }
}

.carousel-thumbs {
    .carousel-nav {
        margin-top: 50px;

        .carousel-cell {
            width: 33.33%;
            margin: 0 10px 0 0;
            opacity: 0.6;

            &.is-nav-selected {
                opacity: 1;
            }
        }
    }
}

.carousel-sushi {
    .carousel-cell {
        width: 33.33%;
        margin: 0 10px 0 0;
    }
}


.zone-post_header {
    .carousel {
        .sol-teaser {
            .info {
                font-weight: 4vw;
                opacity: 0.65;
                h3 {
                    opacity: 0.65;
                    font-size: 45px;
                }
            }
        }
    }
}
