#main-nav {
    #comparison-preview-toggle {
        float: right;
        padding: 0;
        color: $ixTuerkis;
        font-size: $headerLinkSize;
        display: block;
        line-height: 99px;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            color: $mainColor;
        }

        &:focus {
            background-color: $headerBg;
        }
    }
    #header-links {
        float: right;
        padding-right: 15px;

        ul {
            li {
                float: left;
                margin: 0;
            }
        }

        a, span {
            margin: 0 5px;
            color: $ixTuerkis;
            font-size: $headerLinkSize;
            padding: 0px;
            display: block;
            line-height: 99px;
            font-weight: 700;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
                color: $mainColor;
            }

            &:focus {
                background-color: $headerBg;
            }
        }

        nav {
            li {
                a,span {
                    &:hover {
                        background-color: $headerBg;
                    }
                }
            }
        }
        #header-cart-count {
            display: none;
            position: absolute;
            background: #ef4134;
            color: white;
            font-size: 14px;
            text-align: center;
            line-height: 20px;
            height: 20px;
            min-width: 20px;
            border-radius: 10px;
            top: 50%;
            right: -8px;
        }
    }

    .login {
        display: block;
        padding: 0;
        line-height: 100px;
        color: $ixTuerkis;
        font-size: $headerLinkSize;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
        float: right;
        margin: 0 5px;
        height: 100px;
        box-shadow: none;

        .btn-group {
            vertical-align: baseline;
        }

        .dropdown-menu {
            z-index: 9999;
            padding: 20px 10px;
            border-radius: 2px;
            position: fixed;
            top: 135px;
            right: 0;
            margin: auto;
            width: 300px;

            form {

                .form-group {
                    &:focus {
                        border: none;
                    }

                    input {
                        width: 100%;
                        border-radius: 2px;
                        outline: 0;

                        &::placeholder {
                            color: $lightGreyColor3;
                            opacity: 1;
                            font-size: 14px;
                            font-weight: 300;
                        }
                    }

                }

                .clearfix {
                    height: auto;
                    width: auto;

                    button[type="submit"] {
                        width: 100%;
                        border-radius: 2px;
                    }
                }

                h3 {
                    margin: 0;
                    font-size: 0.8em;
                }

                .register-button {
                    text-transform: none;
                    padding-bottom: 10px;

                    &::first-letter {
                        text-transform: capitalize;
                    }
                    a {
                        color: $linkColor;
                    }

                }

                .forgot-password-button {
                    text-transform: lowercase;

                    &::first-letter {
                        text-transform: capitalize;
                    }

                    a {
                        color: $linkColor;
                    }
                }
            }
        }
    }

    #lang-selector {
        .lang-select {
            a {
                text-transform: none;
            }
        }
    }
}

.nav>li>a:hover {
    background-color: $headerBg;
}
.nav>li>a[title="Wishlist"] {
    background-color: $headerBg;
}
.nav>li>a[title="Download Center"] {
    background-color: $headerBg;
}

.nav>li>a[title="Warenkorb"] {
    background-color: $headerBg;
}

.main-navigation {
    .nav>li>a:hover {
        background-color: $ligthGreyColor;
    }

    .nav>li>span:hover {
        background-color: $ligthGreyColor;
    }

    .nav>li:hover {
        cursor: default;
        background-color: $ligthGreyColor;
    }

    .nav>li:hover >a {
        cursor: default;
        background-color: $ligthGreyColor;
    }

    .nav>li:hover >span {
        cursor: default;
        background-color: $ligthGreyColor;
    }

    .navbar-nav {
        li {
            >span {
                color: $headerColor;
                padding: 0px 10px;
                line-height: 99px;
                font-weight: 300;
                font-size: 18px;
                //text-transform: uppercase;
            }
        }

        li.active {
            a {
                color: $ixTuerkis;
            }
        }

        .menu_level_1 {
            li {
                a {
                    display: block;
                    white-space: nowrap;
                }

                span {
                    white-space: nowrap;
                    font-size: 18px;
                    margin: -30px 0px;
                    line-height: 30px;
                }

                &:hover > .menu_level_2 {
                    @media screen and (min-width: 1014px) {
                        display: block;
                    }
                }

            }
        }

        .menu_level_2 {
            display: none;
            position: absolute;
            left: 100%;
            top: 0px;
            background-color: $menuBackgroundColor;
            padding-left: 0px;
            padding-top: 10px;
            border-left:1px solid $mainColor;

            li {
                list-style-type: none;

                a {
                    &:focus, &:hover {
                        color: $mainColor;
                    }
                }

                &:hover > .menu_level_3 {
                    @media screen and (min-width: 1014px) {
                        display: block;
                    }
                }
            }
        }

        .menu_level_3 {
            display: none;
            position: absolute;
            left: 100%;
            top: 0px;
            background-color: $headerActiveBg;
            height: 100%;
            padding-left: 0px;
            padding-top: 10px;
            border-left:1px solid $mainColor;

            li {
                list-style-type: none;

                a {
                    &:focus, &:hover {
                        color: $mainColor;
                    }
                }
            }
        }
    }
}

.pagination {
    margin: 0 0 10px 0;
}

/* Mobile main menu */
@media screen and (max-width: 1015px) {

    .mobile-wrapper {
        height: 60px;
        overflow: hidden;
    }

    #main-nav {
        #comparison-preview-toggle {
            line-height: 60px;
        }

        #header-links {
            text-align: right;
            margin: 10px;

            li {
                a {
                  line-height: 40px;
                }
            }
        }

        .login {
            line-height: 60px;
        }
    }

    .main-navigation {
        .navbar-nav {
            display:block;
            width: 100%;
            li {
                :indeterminate.submenu-trigger {
                    display: block;
                    color: $whiteColor;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    cursor: pointer;
                    font: normal normal normal 26px/1 FontAwesome;

                    &::before {
                        display: inline-block;
                        content: "\f107";
                    }
                }

                span, a {
                    height: 50px;
                    background: $menuBackgroundColor;
                    width: 100%;
                    display: inline-block;
                    line-height: 1.75;
                    padding: 13px 0 13px 15px;
                    font-size: 15px;
                }
            }

            .menu_level_1 {
                position: relative;
                list-style-type: none;
                z-index: 10;
                top: 100%;
                left: 0;
                min-width: 180px;
                display: none;
                padding: 0;
                margin: 0;
                border-top: 1px solid $greyDarkColor1;

                li {
                    position: relative;

                    .submenu-trigger {
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 0;
                        color: $whiteColor;
                        width: 50px;
                        height: 50px;
                        text-align: center;
                        background: $ixLightGray;
                        padding-top: 15px;
                        cursor: pointer;
                        font: normal normal normal 26px/1 FontAwesome;

                        &::before {
                            display: inline-block;
                            content: "\f107";
                        }
                    }

                    span, a {
                        font-size: 16px;
                        text-transform: none;
                        padding: 8px 15px 8px 25px;
                        height: 50px;
                        margin: 0;
                    }
                }

                li.submenu-active {
                    .submenu-trigger {
                        &::before {
                            content: "\f106";
                        }
                    }

                    .menu_level_2 {
                        display: block;
                    }
                }

                a, span {
                    &:focus, &:hover {
                        color: $whiteColor;
                        background-color: $mainColor;
                    }
                }

                // 2 deep
                .menu_level_2 {
                    position: relative;
                    list-style-type: none;
                    z-index: 10;
                    top: 100%;
                    left: 0;
                    min-width: 180px;
                    display: none;
                    padding: 0;
                    margin: 0;
                    border: none;
                    border-top: 1px solid $greyDarkColor1;

                    li {
                        position: relative;

                        .submenu-trigger {
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 0;
                            color: $whiteColor;
                            width: 50px;
                            height: 50px;
                            text-align: center;
                            background: $greyColor2;
                            padding-top: 15px;
                            cursor: pointer;
                            font: normal normal normal 26px/1 FontAwesome;

                            &::before {
                                display: inline-block;
                                content: "\f107";
                            }
                        }

                        a, span {
                            font-size: 15px;
                            text-transform: none;
                            padding: 8px 15px 8px 33px;
                            height: 50px;
                            margin: 0;

                            &:hover {
                                color: $whiteColor;
                            }
                        }
                    }

                    li.submenu-active {
                        .submenu-trigger {
                            &::before {
                                content: "\f106";
                            }
                        }

                        .menu_level_3 {
                            display: block;
                        }
                    }

                    a, span {
                        &:focus, &:hover {
                            color: $whiteColor;
                            background-color: $mainColor;
                        }
                    }

                    // 3 deep
                    .menu_level_3 {
                        position: relative;
                        list-style-type: none;
                        z-index: 10;
                        top: 100%;
                        left: 0;
                        min-width: 180px;
                        padding: 0;
                        display: none;
                        border: none;
                        background-color: $menuBackgroundColor;
                        border-top: 1px solid $greyDarkColor1;

                        li {
                            position: relative;
                            span, a {
                                font-size: 15px;
                                text-transform: none;
                                padding: 8px 15px 8px 41px;
                            }

                            a, span {
                                &:focus, &:hover {
                                    color: $whiteColor;
                                    background-color: $mainColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#main-nav #comparison-preview {
    padding: 0;
}
