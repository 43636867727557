/* -===== ACCORDION =====- */
.accordion_wrapper {
    margin-bottom:30px;
    border: 1px solid #00ABC5;
    background-color: white;
}
.accordion_wrapper .panel-body {
    border: solid 1px #f5f5f5;
    border-top: 0 none;
    padding: 20px;
    margin-bottom: 9px;
}

.accordion_wrapper .panel-body p:last-child {
    margin-bottom: 0;
}

.accordion_wrapper .panel-heading {
    margin-bottom: 9px;
}

.accordion_wrapper .accordion_content_wrapper
{
    max-height: 0px;
    padding: 0;
    margin: 0;
    border: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.5s ease;
    -moz-transition: max-height 0.5s ease;
    -o-transition: max-height 0.5s ease;
    transition: max-height 0.5s ease;
}
.accordion_wrapper[data-state="open"] .accordion_content_wrapper {
    max-height: 100%;
    max-height:initial;
}
.accordion_wrapper .accordion_content {
    padding: 0 50px 20px 80px;
    font-size: 18px;
}
.accordion_wrapper .accordion_headline {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    padding: 20px 50px 20px 80px;
    position: relative;
}

.accordion_wrapper .accordion_headline.no-index {
    padding-left: 30px;
}

.accordion_wrapper .accordion_headline .accordion_headline_index {
    position: absolute;
    left: 40px;
    top: 20px;
    color: #00ABC5;
    line-height: 37px;
    font-weight: bold;
}
.accordion_wrapper .accordion_headline .accordion_collapse_ui svg {
    position: relative;
    right: -30px;
    color: #00ABC5;
    width: 30px;
    height: 30px;
}
.accordion_wrapper[data-state="open"] .accordion_collapse_ui svg {
    transform: rotate(180deg)
}
.accordion_wrapper .accordion_headline h3 {
    color:#00294d;
    font-size: 26px;
    margin: 0;
    line-height: 37px;
}

.accordion_content ul {
    list-style: none;
    margin:0 0 0 15px;
    padding:0;
}

.accordion_content ul li{
    display: flex;
}
.accordion_content ul li::before {
    content: "\25a0";
    color: #00ABC5;
    display: inline-block;
    min-width: 15px;
    max-width: 15px;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
    flex: 1;
}

.accordion_content table {
    font-family: inherit;
    font-weight:300;
    color:black;
    font-size: 14px;
    margin: 0;
    padding:0;
    text-align: left;
}
.accordion_content td {
    padding-bottom:30px !important;
    padding-right:50px !important;
}
.accordion_content td p,
.accordion_content td ul li,
.accordion_content td ul li a
{
    font-size:inherit;
    color: inherit;
    font-weight:inherit;
    text-align: inherit;
}
.accordion_content td ul li a:hover {
    text-decoration: underline;
}
.accordion_content td p {
    margin:0;
    padding:0;
}
/* -===== ENDE ACCORDION =====- */
