.user-menu {
  .logout {
    width: 100%;
  }

  ul {
    font-size: 14px;
    line-height: 50px!important;
  }

}