form[name="cart"], .table.cart-summary {
    padding-bottom: 20px;

    p {
        font-weight: 700;
        font-size: 24px;
        color: $greyColor3;
    }

    table {

        thead {
            tr {

                th {
                    background-color: $ligthGreyColor;
                    color: $greyDarkColor1;
                    border: none;
                    padding-left: 10px;

                    &:last-child {
                        text-align: center;
                    }
                }
            }
        }

        tbody {
            border-bottom: 3px solid $mainColor;
            border-top: 3px solid $mainColor;

            tr {
                td {
                    padding: 0;
                    padding-left: 10px;
                    vertical-align: middle;
                    background-color: $whiteColor;

                    a {
                        color: $headerActiveBg;
                    }

                    .form-group {
                        input[type="number"] {
                            margin-top: 15px;
                            color: $headerActiveBg;
                            border-radius: 2px;
                        }

                        .checkbox {
                            text-align: center;

                            label {
                                padding: 0;
                            }

                            input[type="checkbox"] {
                                position: relative;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

table.cart-summary {
    margin-top: 60px;

    caption.cart-title {
        padding-left: 10px;
        font-size: 16px;
        font-weight: 700;
    }

    tbody {
        tr {
            &:last-child {
                border-bottom: 3px solid $mainColor;
            }
        }
    }
}

.cart-title {
    background-color: $ligthGreyColor;
    color: $greyDarkColor1;
    font-size: 24px;
    font-weight: 700;
    padding: 10px;
    border-bottom: 3px solid $mainColor;
}

.cart-wrapper {
    border-bottom: 3px solid $mainColor;
    margin-bottom: 40px;
}

.cart-table {
    margin-top: 60px;

    thead {
        tr {
            background-color: $headerActiveBg;

            th {
                color: $greyDarkColor1;
                border: none;
                padding-left: 10px;

                &:last-child {
                    text-align: center;
                }
            }
        }
    }

    tbody {
        border-bottom: 3px solid $mainColor;
        border-top: 3px solid $mainColor;

        tr {
            width: 100%;

            td {
                padding: 0;
                padding-left: 10px;
                vertical-align: middle;

                &.cart-list-line-quantity {
                    text-align: center;
                }

                &.cart-list-line-price {
                    text-align: center;
                }

                &.cart-list-line-price-total {
                    text-align: center;
                }

                a {
                    color: $headerActiveBg;
                }

                .form-group {
                    input[type="number"] {
                        margin-top: 15px;
                        color: $headerActiveBg;
                        border-radius: 2px;
                    }

                    .checkbox {
                        text-align: center;

                        label {
                            padding: 0;
                        }

                        input[type="checkbox"] {
                            position: relative;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

.cart {
    table {
        &.cart-summary {
            td {
                text-align: right;
            }
        }

    }

    label[for="summary_terms"] {
        float: left;
    }

    .button-main, .button-warning {
        margin: 5px 0 5px 0;
    }

    #summary_notes {
        margin-top: 20px;
    }

    .panel-body.address-box {
        min-height: 210px;

    }
    &-sku {
        margin: 8px 0;
        display: flex;
        align-items: center;
        label {
            white-space: nowrap;
            margin-right: 15px;
        }
        &-submit {
            margin-left: 15px;
        }
    }
}

.order-history {
    tr[aria-expanded="false"] .panel-title > span:before {
        font-family: FontAwesome;
        content:"\f078";
        padding-right: 5px;
    }
    tr[aria-expanded="true"] .panel-title > span:before {
        font-family: FontAwesome;
        content:"\f077";
        padding-right: 5px;
    }
}

