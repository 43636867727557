%button-main {
    background-color: $mainColor;
    color: $whiteColor;
    border: none;
    border-radius: 2px;
    padding: 10px 20px;
    outline: 0;

    &:hover, &:focus {
        background-color: $redColor;
        text-decoration: none;
        color: $whiteColor;
    }
}

.button-main {
    @extend %button-main;
}

.button-small {
    padding: 3px 15px;
}

.button-warning {
    @extend %button-main;
    background-color: $orangeColor;

    &:hover {
        background-color: darken( $orangeColor, 30% );
    }
}