// Specific style file

/* bootstrap inputs and buttons override */
.form-control {
    border-radius:$borderRadius;
    background:hsl(0, 0, 95);
    box-shadow:none;
}
.btn {
    border-radius:$borderRadius;
}
.btn-primary {
    $btnPrimaryBg: $mainColor;
    color:$whiteColor;
    background-color:$btnPrimaryBg;
    border-color:$btnPrimaryBg;
    &:active,
    &.active {
        background-color:$btnPrimaryBg;
        border-color:$btnPrimaryBg;
    }
    &:hover,
    &:active:hover,
    &.active:hover,
    &.active:focus,
    &.active.focus,
    &:active:focus,
    &:active.focus,
    &:focus,
    &.focus {
        background-color:desaturate(darken($btnPrimaryBg, 8%), 8%);
        border-color:desaturate(darken($btnPrimaryBg, 8%), 8%);
    }
}
.btn-dark {
    $btnDarkBg: hsl(0, 0, 20);
    color:$whiteColor;
    background-color:$btnDarkBg;
    border-color:$btnDarkBg;
    &:active,
    &.active {
        background-color:$btnDarkBg;
        border-color:$btnDarkBg;
    }
    &:hover,
    &:active:hover,
    &.active:hover,
    &.active:focus,
    &.active.focus,
    &:active:focus,
    &:active.focus,
    &:focus,
    &.focus {
        color:$whiteColor;
        background-color:lighten($btnDarkBg, 8%);
        border-color:lighten($btnDarkBg, 8%);
    }
}

.error-input {
   .form-control {
       background:hsl(0, 100, 94);
   }
   .errors {
       list-style-type:none;
       margin:0 0 1.5em;
       padding:0;
       color:red;
       font-size:0.875em;
   }
}

.search-inputs {
    border-top:1px solid hsl(0, 0, 75);
    border-bottom:1px solid hsl(0, 0, 75);
    padding:1em 0 0;
    .input-group {
        margin:0 0 1em;
        .btn {
            font-weight:700;
            text-transform:uppercase;
        }
    }
}

.section-red {background-color: #fcc;}
.section-blue {background-color: #99f;}
.section-gray {background-color: #eee;}

.bg-gray {background-color:hsl(220, 2, 35);}
.bg-gray-light {background-color:hsl(180, 4, 95);}

.bg-gray, .bg-gray-light {
    & + & {margin-top:-$gap; padding-top:$gap;}
}

.main-content-block .zone-right {
    background-color:hsl(0, 0, 95);
    padding:20px;
    border:1px solid hsl(0, 0, 80);
}

.list-numbered {
    counter-reset: my-counter;
    .list-item::before {
        counter-increment: my-counter;
        content: counter(my-counter);
        display: block;
        font-size:1.75em;
        font-weight:700;
        color:$mainColor;
    }
}

.swiper-container {margin:0}

.breadcrumb-wrapper {
    background:hsl(0, 0, 95);
    margin:0 0 1.5em;
    padding:1em 0;
    .breadcrumb {
        list-style-type:none;
        margin:0;
        padding:0;
        background:transparent;
        &::after {
            @extend %clearfix;
        }
        li {
            float:left;
            + li {
                &:before {
                    content:">";
                    display:inline-block;
                    margin:0 0.5em;
                    color:hsl(0, 0, 80);
                }
            }
        }
        a {
            color:$mainColor;
            font-weight:700;
            font-size:0.875em;
        }
        span {
            font-weight:700;
            font-size:0.75em;
        }
    }
    @media screen and (max-width:$break-sm) {
        padding:0.5em 0;
        .breadcrumb {
            a {
                font-size:0.75em;
            }
        }
    }
    @media screen and (max-width:$break-xs) {
        display:none;
    }
}
