li .view-type-line {
  display: inline-block;
}

.category-children {
  padding: 25px 0;
}

.ng-category .body {
  margin: 40px 0;
}