#eucookielaw {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    background-color: $lightGreyColor1;
    color: $whiteColor;
    display: none;
    font-size: 1.3em;
    width: 100%;
    margin-bottom: 20px;

    a {
        color: $blueColor1;
    }
}

#eucookie-wrapper {
    margin: 0 auto;
    text-align: center;
    font-family: inherit;

    p {
        color: $whiteColor;
    }
}

#removecookie {
    border: 0 none;
    height: 16px;
    position: absolute;
    right: 1em;
    top: 21px;
    width: 16px;
}

#eucookie-body {
    padding: 10px 48px;
    position: relative;
    text-align: left;
}

.cookie_space {
    height: 55px;
    display:none;
}


