div.product-item-wrapper {
    position: relative;
    height: 300px;
    width: 100%;
    margin: 10px 0px;
    border: 1px solid $lightGreyColor4;
    border-radius: 2px;
    overflow: hidden;

    a.product-item-link {
        display:block;
        height: 100%;
    }

    div.block-view {
        margin: 0;
        width: 100%;

        .image {
            height: auto;
            width: 100%;
            margin: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .date {
            position: absolute;
            top: 1px;
            right: 3px;
            background-color: transparent;

            i {
                color: $lightGreyColor4;
            }

            a {
                border-radius: 2px;
                border-color: $lightGreyColor4;
                background-color: $whiteColor;

                &:hover {
                    border-color: $mainColor;
                }

                &:hover i {
                    color: $mainColor;
                }
            }
        }

        .info {
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 100px;
            padding-top: 5px;
            padding-bottom: 5px;
            vertical-align: center;
            background-color: $headerActiveBg;
            border-top: 3px solid $mainColor;
            transform: translateY(100px);
            transition: transform 0.3s ease-out;

            .title {
                font-size: 14px;
                font-weight: 600;
                white-space: normal;
                color: $whiteColor;
                line-height: 1.2;
                text-align: center;
                padding-top: 10px;
            }
        }

        &:hover .info {
            transform: translateY(0px);
            transition: transform 0.3s ease-out;
        }


    }
}

.show-info .product-item-wrapper {
  .block-view {
    .info,
    &:hover .info {
      transform: unset;
      transition: unset;
    }
  }
}

.product-details {
    h1 {
        font-size: 35px;
        color: $ixTuerkis;
        font-weight: 500;
    }

    .product-description {
        .advantage > p,
        .details >p {
            font-size: 26px;
        }
    }

    .price {
          color: $orangeColor;
          font-size: 28px;
          line-height: 24px;
          font-weight: bold;
          padding: 0 !important;

        @media screen and (max-width: $break-orig-md) {
            padding-left: 15px !important;
        }

    }

    .product-delivery {

      span {
        color: $orangeColor;
      }
    }

    .product-actions {
      padding-top: 1em;

      .action-item {
        float: left;
        padding: 0 10px 10px 0;
        line-height: 33px;
      }
    }

    .product-relations {
      padding-top: 2em;
      font-size: 16px;

      #equipment {
        .row {
          padding-bottom: 1em;
        }
      }

      .attributtabelle {
        tr:nth-child(odd) {
          background-color: $whiteColor;
        }
        tr:nth-child(even) {
          background-color: $lightGreyColor5;
        }

        td {
          width: 50%;
          font-weight: 300;
          padding: 10px 5px 10px 35px;
        }
      }

        #accessories {
            .productoverview {
                margin-top: 20px;
            }
        }

    }

    .header-img {
        position: relative;
        padding: 36px 0 0 70px;
        margin-bottom: 30px;

        &.filled {
            min-height: 585px;
        }

        .icon {
            position: absolute;
            right: 0;
            top: 0;
            text-align: right;

            .figure {
                margin-top: 10px;
                margin-right: 12px;

                img {
                    width: 64px;
                    height: 64px;
                }
            }
        }

        figure {
            margin-top: 10px;
            margin-right: 12px;
            margin-left: 0;
            display: inline-grid;
            float: left;

            img {
                width: 64px;
                height: 64px;
            }
        }
    }

    .order-table {

        .add-to-comparison-link {
            button {
                color: $lightGreyColor3;
            }
        }



        .action-item {
            float: left;
            padding: 0 0 10px 0;
            line-height: 33px;
        }
    }

    .carousel {
        &-nav {
            .carousel-cell {
                img {
                    width: 100%;
                }
            }
        }
    }
}

.ordertable-header-items {
    margin-top: 20px;
    background-color: $tableHeaderBackground;
    border-bottom: 3px solid $tableHeaderBorderBottom;
    margin-left: 0;
    margin-right: 0;

    @media screen and (max-width: $break-orig-sm) {
        border-bottom: 1px solid $ixTuerkis;
    }

    .ordertable-header-item {
        font-size: 16px;
        color: $tableHeaderColor;
        padding-top: 10px;
        padding-bottom: 10px;
        hyphens: auto;

        &.actions {
            color: $ixTuerkis;
        }
    }
}

.search-page {
  >h1 {
    font-weight: 700;
    font-size: 24px;
    color: $greyColor3;
  }

  .search-form {
    .form-group {
      input {
        position: relative;
        top: 2px;
        padding-left: 10px;
        line-height: 38px;
      }

      button {
        background-color: $mainColor;
        color: $whiteColor;
        border: none;
        border-radius: 2px;
        padding: 10px 20px;

        &:hover {
            background-color: $redColor;
        }
      }
    }
  }

  .ajax-reload {
    .text-content {
      font-weight: 400;
      font-size: 16px;
      color: $greyColor3;
    }
  }

  ul.pagination {
    margin-left: 16px;
  }
}

.expert-tip {
    background-repeat: repeat;
    background-position: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    max-width: 1174px;
    min-height: 520px;
    position: relative;
    cursor: pointer;

    .layer-mobile {
        width: 100%;
        min-height: 520px;
        padding: 48px 48px 0 48px;
    }

    .img-teaser-text {
        max-width: 550px;
        width: 100%;

        h2 {
            color: #d41830;
            font-size: 45px;
            font-family: NettoPro-Light,Arial,sans-serif;
            margin-bottom: 18px;
            line-height: 63px;

            i {
                font-size: 26px;
                line-height: 28px;
                color: #3c3c3c;
                font-family: NettoPro,Arial,sans-serif;
                display: block;
                font-style: normal;
                margin-bottom: 19px;
            }
        }

        p {
            font-size: 18px;
            line-height: 26px;
            font-family: NettoPro-Light,Arial,sans-serif;
            color: #000;
            padding-left: 4px;
            margin-bottom: 0;
        }

    }
}

.group-details {
    .product-finder-list-wrapper
    {
        margin: 0 15px 0 15px;

    }
}


.ordertable-item {
    padding: 10px 0 10px 0;
    border-bottom: 1px solid $generalBorderColor;

    .price {
        color: $mainColor;
        font-size: 16px;
        font-weight: bold;
    }

    .image-preview {
        max-height: 100px;
        max-width: 80px;

        @media screen and (max-width: $break-orig-md - 1px) {
            max-height: 100%;
            max-width: 100%;
            width: auto;
            height: auto;
        }
    }

    .cart-add-item-quantity {
        max-width: 60px;
        border:1px solid $lightGreyColor6;
    }

    img:last-of-type {
        position: absolute;
        z-index: -1;
        //-webkit-transition: .3s ease-in-out;
        //transition: .3s ease-in-out;
        opacity: 0;
    }
    img {
        &:hover ~ img {
            //top: 0;
            //left: 100%;
            transform: translate(50%, -50%);
            opacity: 1;
            z-index: 10;
        }
    }
    .table-actions {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        .action-item {
            padding-right: 10px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            &.cart-add {
                flex-wrap: nowrap;
            }
        }

        @media screen and (max-width: $break-orig-lg ) and (min-width: $break-orig-md ){
            flex-direction: column;
        }


        @media screen and  (max-width: $break-orig-md ){
            justify-content: flex-start;
        }

    }

    .articlenumber {
        text-align: center;

        @media screen and (max-width: $break-orig-md) {
            text-align: left;
        }
    }

    .price {
        text-align: center;

        @media screen and (max-width: $break-orig-md - 1px) {
            text-align: left;
            margin: 13px 0;
            font-size: 20px;
        }
    }

    .preview {
        @media screen and (max-width: $break-orig-md) {
            min-height: 270px;
        }
    }

    .add-to-wishlist-select {
        max-width: 95px;
    }
}


.action-item {
    float: left;
    padding: 0 0 10px 0;
    line-height: 33px;
}
