/* List START */
.list-item {
    .vl2 {
        .info {
            position: unset;
            background: rgba(0, 0, 0, 0.75);

            .title {
                padding: 2px 15px;
                color: inherit;
            }
        }
    }
}

.list-item {
    padding: 5px;

    .view-type-standard {
        width: 100%;
    }
}

.zone-pre_footer{
    .list-item {
        .vl2  {
            .info {
                width: 145px;
                word-wrap: break-word;
                overflow: hidden;
            }
        }
    }
}

