//
// Pagination (multiple pages)
// --------------------------------------------------

.pagination {

  // Default
  > li > a, > li > span {
    color: $greyColor1;
    border-color: $lightGreyColor1;
    background-color: $lightGreyColor1;
    margin: 0 2px;
    line-height: 1;
    padding: 4px 8px;
    font-size: 16px;
  }

  // First and last
  > li:first-child > a,
  > li:first-child > span,
  > li:first-child > span:hover,
  > li:last-child > a,
  > li:last-child > span,
  > li:last-child > span:hover {
    border-radius:0;
    background-color: $greyDarkColor1;
    border-color: $greyDarkColor1;
    color: $ligthGreyColor;
  }

  // Hover
  > li > a:hover,
  > li > a:focus,
  > li > span:hover,
  > li > span:focus {
    background-color: $whiteColor;
    border-color: $lightGreyColor1;
    color: $greyColor1;
  }

  // Active
  > .active > a,
  > .active > a:hover,
  > .active > a:focus,
  > .active > span,
  > .active > span:hover,
  > .active > span:focus {
    background-color: $lightGreyColor3;
    border-color: $lightGreyColor3;
    color: $ligthGreyColor;
  }

  // Disabled
  > .disabled > span,
  > .disabled > span:hover,
  > .disabled > span:focus,
  > .disabled > a,
  > .disabled > a:hover,
  > .disabled > a:focus {
    cursor: no-drop;
    border-top: 1px solid;
    border-bottom: 1px solid;
    color: $greyColor1;
    border-color: $lightGreyColor1;
    background-color: $lightGreyColor1;
  }

}