// Default colors
$mainColor: #ef4134;  // red
$redColor: #dd2a1d;  // red color - button background
$headerBg: #403e41;  // medium grey (the main grey in the header)
$headerActiveBg: #231f20;  // dark grey (in search)
$greyDarkColor1: #333333;  // dark grey color
$greyColor1: #525252;  // grey color medium
$greyColor2: #595959;  // grey color medium
$greyColor3: #57595b;  // grey color medium
$linkColor: #0697E0;  // ligth blue link color
$blueColor1: #2d71c1; // blue color cookie
$whiteColor: #ffffff;  // white
$ligthGreyColor: #f2f2f2;  // light grey
$lightGreyColor1: #c4c4c4; // cookie grey
$lightGreyColor2: #ededed;  // footer grey color
$lightGreyColor3: #999999;  // light grey
$lightGreyColor4: #d1d3d4;  // light grey
$lightGreyColor5: #f0f0f0;  // very light grey
$lightGreyColor6: #cccccc;  // light grey color
$lightGreyColor7: #dddddd;  // light grey color for borders
$blackColor: #000000;  // black
$whiteColor: #ffffff; // white
$orangeColor: #f6821b;  // orange on products page

// Element Color definitions
$tableHeaderBackground: $ligthGreyColor;
$tableHeaderColor: $greyDarkColor1;
$tableHeaderBorderBottom: $ligthGreyColor;

@import './alerts';

// Generic
.center {
    text-align: center;
}

.overflow-hidden {
    overflow: hidden;
}


// code for product page
.wishlist {
    &.flex-container {
        display: flex;
        flex-wrap: wrap;

        .same-high {
            display: flex;
        }
    }

    .add-to-wishlist-select {
        button[aria-expanded="true"] ~ .tooltip {
            opacity: 0;
            z-index: 0;
        }
    }
}

// Code for radio buttons select
.bootstrap-select.radio-picker.btn-group.show-tick {

    .dropdown-menu {

        li {
            a {
                // Label
                span.text {
                    margin-left: 18px;
                    margin-right: 0;
                }

                // Icon general
                span.check-mark {
                    display: inline-block;
                    left: 10px;
                    position: absolute;
                    margin-top: 5px;

                    // Icon unchecked
                    &:before {
                        content: "\F111";
                    }
                }
            }

            // Icon checked
            &.selected {
                a  {
                    span.check-mark:before {
                        content: "\F192";
                    }
                }
            }
        }

        .btn-close {
            margin: 6px;
            background: none;
            border: 2px solid $lightGreyColor6;
            font-weight: bold;
            display: block;
            text-align: center;
            color: $blackColor;
        }
    }
}

form[name="import_csv"] {

    .center-block {
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    fieldset {
        border: 1px groove #ddd !important;
        padding: 0 1.4em 1.4em 1.4em !important;
        margin: 0 0 1.5em 0 !important;
        box-shadow:  0px 0px 0px 0px #000;
        width: 100%;

        legend {
            width: auto !important;
            font-size: 1.2em !important;
            font-weight: bold !important;
            text-align: left !important;
            border: none;
            padding: 10px;
        }
    }

    .widget {
        opacity: 0.5;

        &:hover {
            opacity: 1.0;
        }
    }

    .font-weight-bold {
        font-weight: 700;
    }
}

// Wishlist detail
form[name="wishlist"] {

    .small-device-title {
        display: none;
    }

    .dragger {
        user-select: none;
        -webkit-user-drag: element;
        cursor: move;
    }

     button[disabled] {
        background-color: $lightGreyColor1;
        cursor: no-drop;
    }

    #wishlist_name {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 10px;
        display: none;
        z-index: 200;
        position: absolute;
        margin-right: 10px;
        top: 0;

        &:disabled {
            background-color: $whiteColor;
            padding-left: 0;
        }
    }


    .wishlist-header {
        padding-bottom: 30px;

        &> .form-group {
            margin: 0;

            &> div {
                padding: 0;
                margin-top: 5px;
            }
        }

        #wishlist_submit {
        }

        .wishlist-actions {
            padding-top: 5px;
        }
    }

    #wishlist-name-container {
        z-index: 1;
        position: relative;
        width: 100%;
        margin-right: 5px;
    }

    .wishlist-buttons {
        #wishlist-actions {
            .radio-picker {
                width: 240px;
                float: right;
            }

            .fa-info-circle {
                font-size: larger;
                color: $redColor;
                float: right;
                cursor: help;
                padding-right: 5px;
                padding-top: 10px;
            }

            button[aria-expanded="true"] ~ .tooltip {
                opacity: 0;
                z-index: 0;
            }
        }
    }

    .wishlist-list {
        color: $greyColor3;

        .checkbox {
            margin: 0;
        }

        .item {
            img:last-of-type {
                position: absolute;
                z-index: -1;
                //-webkit-transition: .3s ease-in-out;
                //transition: .3s ease-in-out;
                opacity: 0;
            }
            img {
                &:hover ~ img {
                    //top: 0;
                    //left: 100%;
                    transform: translate(50%, -50%);
                    opacity: 1;
                    z-index: 10;
                }
            }
        }
        .whishlist-item-quantity input {
            max-width: 90px;
            min-width: 70px;
            display: inline;
        }

        .wishlist-header-items {
            background-color: $tableHeaderBackground;
            border-bottom: 3px solid $tableHeaderBorderBottom;
            margin-left: 0;
            margin-right: 0;

            .wishlist-header-item {
                font-size: 16px;
                font-weight: 700;
                color: $tableHeaderColor;
                padding-top: 10px;
                padding-bottom: 0px;
                hyphens: auto;
            }
        }

        .items {
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $tableHeaderBorderBottom;

            .item {
                i {
                    color: $mainColor;
                }
                a {
                    span {

                        &:hover {
                            color: darken( $redColor, 50% );
                        }
                    }
                }


                img.image-preview {
                    max-height: 100px;
                    max-width: 80px;
                }
            }
        }
    }

    .wishlist-item-preview {
        img {
            margin-left: auto;
            margin-right: auto;
        }

    }

    .wishlist-item-note {
        padding-top: 10px;

        label {
            text-decoration: underline;
            color: $redColor;

            &:hover {
                cursor: pointer;
            }
        }

        input, textarea {
            display: none;
            border: 1px solid $lightGreyColor1;
        }
    }

    .wishlist-item-description {
        padding: 10px 0 10px 0;
    }

}

// Wishlist overview
.wishlists {

    .wishlist-block-item {
        border: 1px solid $lightGreyColor3;
        position: relative;
        overflow: hidden;
        padding: 20px;
        text-align: center;
        margin-bottom: 20px;
        min-height: 180px;
        display: block;

        &.wishlist-create {
            h3 {
                margin-top: 10px;
            }
        }
    }

    .ui-sortable-handle {
        &:hover {
            cursor: grab;
        }

        // Applied during the drag
        &.ui-sortable-helper {

            opacity: 0.7;
            transition: opacity 0.3s;
            cursor: grabbing;
            border: 1px solid $lightGreyColor7;
        }

    }

}

.sortable {
    .drop-placeholder {
        background-color: $ligthGreyColor;
        height: 3.5em;
        line-height: 1.2em;
    }
}


/** SPINNER CREATION **/

.loader {
    position: relative;
    text-align: center;
    margin: 15px auto 35px auto;
    z-index: 9999;
    display: block;
    width: 80px;
    height: 80px;
    border: 10px solid rgba(0, 0, 0, .3);
    border-radius: 50%;
    border-top-color: #000;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

/** MODAL STYLING **/

.modal-content {
    border-radius: 0px;
    box-shadow: 0 0 20px 8px rgba(0, 0, 0, 0.7);
}

.modal-backdrop.show {
    opacity: 0.75;
}

.loader-txt {
    p {
        font-size: 13px;
        color: #666;
        small {
            font-size: 11.5px;
            color: #999;
        }
    }
}



@media only screen and (max-width: 768px) {
    form[name="wishlist"] {
        .wishlist-list {
            .wishlist-items {
                .items:nth-child(odd) {
                    background-color: $ligthGreyColor;
                }
            }

            .item {
                text-align: center;
                .file-format {
                    text-align: center;
                }
            }

            .small-device-title {
                display: inline;
            }


            .wishlist-item-select input[type=checkbox]{
                transform: scale(2);
            }

        }
    }
}


@media only screen and (max-width: 992px) {

    #wishlist_submit {
        margin-top: 10px;
        float: right;
    }

    .wishlist-list {
        .item {
            margin-top: 10px;

            span {
                font-weight: 700;
                color: $headerActiveBg;
            }
        }
    }

    .whishlist-item-delete a {
        font-size: xx-large;
    }

}
