// Layout style file
header {

    &.site-header {
        background: $headerBg;
        height: $headerHeight;

        position: fixed;
        z-index: 1000;
        width: 100%;
        border-bottom: 1px solid $ixTuerkis;

        .container {
            position: relative;
        }

        @media screen and (max-width: $break-md) {
            .container {
                padding: 0;
                width: 100%;
            }
        }
        @media screen and (max-width: $break-sm) {
            & {
                height: auto;
                background: $mainColor;
                position: absolute;

                .container {
                    padding: $headerHeightSm 0 0;
                    margin: 0;
                    width: 100%;
                }
            }
        }
    }
}

.header-spacer {
    height: $headerHeight;

    @media screen and (max-width: 1015px) {
        height: 70px;
    }
}

#logo {
    display: block;
    width: 175px;
    height: $headerHeight;
    position: absolute;
    left: $gutter;
    top: 0;
    background: $mainColor url(../../images/static/infolox_logo_white.svg) no-repeat center center;
    background-size: 118px auto;
    overflow: hidden;
    text-indent: -999em;
    @media screen and (max-width: $break-lg) {
        width: 125px;
        background-size: 98px auto;
    }
    @media screen and (max-width: $break-md) {
        left: 0;
    }
    @media screen and (max-width: $break-sm) {
        left: 0;
        height: $headerHeightSm;
        width: 115px;
        background-size: 85px auto;
    }
}

#main-nav {
    padding: 0 60px 0 225px;
    @extend %clearfix;

    #lang-selector {
        float: right;
        margin: 0 10px;

        .current-lang {
            display: block;
            padding: 0 10px;
            height: $headerHeight;
            line-height: $headerHeight;
            color: $headerColor;
            font-size: 15px;
            font-weight: 700;
            text-decoration: none;
            //text-transform:uppercase;
        }

        &.open {
            .current-lang {
                background: $ligthGreyColor;
            }
        }

        .lang-select {
            border: none;
            background: $menuBackgroundColor;
            border-radius: 0;
            margin: 0;
            box-shadow: none;
            padding: 10px 0;

            a {
                color: $headerColor;
                padding: 5px 20px;
                text-decoration: none;
                text-transform: uppercase;

                &:hover,
                &:focus {
                    color: $mainColor;
                    background: transparent;
                }
            }
        }
    }

    @media screen and (max-width: $break-lg) {
        padding-left: 125px;
        #lang-selector {
            .current-lang {
                font-size: 13px;
            }
        }
    }
    @media screen and (max-width: $break-sm) {
        display: none;
        background: $headerBg;
        padding: 0;
        #lang-selector {
            text-align: right;
            margin: 10px;

            .current-lang {
                height: auto;
                line-height: 40px;
            }

            .lang-select {
                left: auto;
                right: 0;
            }
        }
        .mainnav-active & {
            display: block;
        }
    }
}

.main-navigation {
    .navbar-nav {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $ixBlue;
            padding: 5px 10px;
            font-weight: 300;
            font-size: 18px;
            text-decoration: none;
            //text-transform:uppercase;
        }

        > li {
            float: left;
            position: relative;
            display: flex;

            > a {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 300;
                font-size: 18px;
                text-decoration: none;
                background-color: $headerBg;
            }

            &.active {
                background: $mainColor;

                > a {
                    background: $mainColor;
                    color: $mainColor;
                }
            }

            > .submenu-trigger {
                @extend %fontawesome;
                display: none;
            }
        }

        .menu_level_1 {
            list-style-type: none;
            display: none;
            position: absolute;
            z-index: 10;
            top: 100%;
            left: 0;
            min-width: 180px;
            background: $menuBackgroundColor;
            margin: 0;
            padding: 10px 0;

            > li {
                > a {
                    display: block;
                    white-space: nowrap;

                    &:hover,
                    &:focus {
                        color: $mainColor;
                    }
                }
            }
        }
    }

    @media (min-width: $break-sm + 1) {
        .navbar-nav {
            > li {
                &:hover,
                &:focus {
                    background: $headerActiveBg;

                    .menu_level_1 {
                        display: block;
                    }
                }
            }
        }
    }
    @media screen and (max-width: $break-sm) {
        clear: both;
        .navbar-nav {
            flex-direction: column;

            > li {
                float: none;
                width: 100%;
                background: transparent;
                margin: 1px 0 0;
                display: flex;
                height: 50px;

                &.active {
                    background: transparent;
                }

                > a {
                    display: flex;
                    line-height: 1.75;
                    padding: 13px 0 13px $gutter;
                    font-size: 14px;
                    background: $greyColor1;
                }

                &[data-submenu='true'] {
                    > a {
                        margin-right: 51px;
                    }
                }

                > .submenu-trigger {
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    color: $whiteColor;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    background: $ixLightGray;
                    font-size: 26px;
                    cursor: pointer;

                    &::before {
                        display: inline-block;
                        content: "\f107";
                    }
                }

                &.submenu-active {
                    > .submenu-trigger::before {
                        content: "\f106";
                    }

                    .menu_level_1 {
                        display: block;
                        width: 100%;
                        position: absolute;
                    }
                }
            }

            .menu_level_1 {
                position: relative;
                background: hsl(0, 0, 35);
                margin: 1px 0 2px;

                > li {
                    > a {
                        font-size: 13px;
                        text-transform: none;
                        padding: 8px 15px 8px 25px;

                        &:hover,
                        &:focus {
                            color: $whiteColor;
                        }
                    }
                }
            }
        }
    }
}

.navbar-search {
    position: absolute;
    right: $gutter;
    top: 20px;
    display: block;
    width: 60px;
    height: 60px;
    z-index: 9;

    .search-query {
        border: none;
        box-shadow: none;
        height: 60px;
        line-height: 60px;
        padding: 0 20px;
        color: $whiteColor;
        transition: all 200ms;

        &:focus {
            outline: none;
        }
    }

    .search-submit {
        position: absolute;
        right: 0;
        top: 0;
        height: 60px;
        width: 60px;
        line-height: 60px;
        border: none;
        background: transparent;
        z-index: 11;
        color: $whiteColor;
        padding: 0;
    }

    @media (min-width: $break-sm + 1) {
        .searchbox-active .filled & {
            z-index: auto;
        }
        .search-query {
            width: 60px;
            border-radius: 500px;
            background: $headerActiveBg;
            position: absolute;
            right: 0;
        }
        .searchbox-active & {
            .search-query {
                width: 950px;
            }
        }
        .search-submit {
            text-indent: -999em;
            overflow: hidden;
            border-radius: 500px;
        }
    }
    @media screen and (max-width: $break-sm) {
        display: none;
        background: $headerBg;
        position: relative;
        top: auto;
        right: auto;
        clear: both;
        width: 100%;
        padding: 10px;
        .search-query {
            background: rgba(255, 255, 255, .1);
            color: $ixTuerkis;
            width: 100%;
            height: 40px;
            line-height: 40px;
            padding: 0 60px 0 15px;
        }
        .search-submit {
            right: 10px;
            top: 10px;
            width: 50px;
            height: 40px;
            line-height: 40px;
            text-transform: uppercase;
            font-weight: 700;
            background: $ixTuerkis;
        }
        .searchbox-active & {
            display: block;
        }
    }
}

#searchbox-toggle {
    position: absolute;
    right: $gutter;
    top: 20px;
    display: block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    border-radius: 500px;
    background-color: $ixTuerkis;
    color: $whiteColor;
    text-align: center;
    border: none;
    padding: 0;
    z-index: 10;

    &:hover,
    &:focus {
        color: $whiteColor;
    }

    @media screen and (max-width: $break-sm) {
        right: $headerHeightSm + 1px;
        top: 0;
        border-left: 1px solid $headerBg;
        background: transparent;
        border-radius: 0;
        height: $headerHeightSm;
        width: $headerHeightSm + 1px;
        line-height: $headerHeightSm;
        &::before,
        &::after {
            content: "";
            display: block;
            width: 20px;
            height: 2px;
            background: $whiteColor;
            transition: all 400ms;
            position: absolute;
            left: 50%;
            top: 50%;
            opacity: 0;
            transform: translate(-50%, -50%) rotate(0);
        }
        &::after {
            transform: translate(-50%, -50%) rotate(0);
        }
        i.fa {
            opacity: 1;
            transition: all 200ms ease-out 150ms;
            transform: rotateY(0);
        }
        .searchbox-active & {
            //background: $headerBg;

            i.fa {
                opacity: 0;
                transition: all 150ms;
                transform: rotateY(90deg);
            }

            &::before,
            &::after {
                opacity: 1;
                transform: translate(-50%, -50%) rotate(135deg);
            }

            &::after {
                transform: translate(-50%, -50%) rotate(-135deg);
            }
        }
    }
}

.search-page {
    .result-list {
        #news {
            padding-top: 20px;
        }
    }
}

#mainnav-toggle {
    display: none;

    .hamburger {
        display: block;
        width: 20px;
        height: 2px;
        background: $whiteColor;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -1px 0 0 -10px;
        transition: background 400ms ease-out 200ms;

        &::before,
        &::after {
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background: $whiteColor;
            transition: all 400ms;
        }

        &::before {
            top: -6px;
        }

        &::after {
            bottom: -6px;
        }
    }

    .mainnav-active & {
        //background: $headerBg;

        .hamburger {
            background: transparent;
            transition: background 100ms ease-out;

            &::before {
                transform: translate(0, 6px) rotate(135deg);
            }

            &::after {
                transform: translate(0, -6px) rotate(-135deg);
            }
        }
    }

    @media screen and (max-width: $break-sm) {
        display: block;
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 0;
        width: $headerHeightSm + 1px;
        height: $headerHeightSm;
        border-left: 1px solid $headerBg;
    }
}

.pagination {
    > li > a, > li > span {
        color: #333;
        border-color: #d1d3d4;
    }

    > li > a:hover,
    > li > a:focus,
    > li > span:hover,
    > li > span:focus {
        background-color: inherit;
        border-color: #d1d3d4;
        color: #ef4136;
    }

    > .active > a,
    > .active > a:hover,
    > .active > a:focus,
    > .active > span,
    > .active > span:hover,
    > .active > span:focus {
        background-color: #ef4136;
        border-color: #ef4136;
    }

    > li:first-child > a,
    > li:first-child > span,
    > li:last-child > a,
    > li:last-child > span {
        border-radius: 0;
    }

    > .disabled > span,
    > .disabled > span:hover,
    > .disabled > span:focus,
    > .disabled > a,
    > .disabled > a:hover,
    > .disabled > a:focus {
        border-top: none;
        border-bottom: none;
        color: inherit;
    }
}

.site-error {
    padding: 10em 0 8em;
    text-align: center;
    @media screen and (max-width: $break-sm) {
        padding: 6em 0 4em;
    }
    @media screen and (max-width: $break-xs) {
        padding: 3em 0 2em;
    }
}

footer {
    background: $ixLightGray;
    color: $whiteColor;
    font-size: 14px;
    line-height: 1.42857;
    margin-top: 60px;

    a {
        color: $whiteColor;
        text-decoration: none;

        &:hover {
            //color:$whiteColor;
            text-decoration: none;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: $whiteColor;
    }

    h3 {
        text-transform: uppercase;
        margin: 0 0 0.75em;
        font-size: 16px;
    }

    > .container {

    }

    .footer-cell {
        display: table-cell;
        padding: 24px 20px;
    }

    .footer-menu {
        background: hsl(269, 3, 25);
        width: 175px;

        > ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            > li {
                text-transform: uppercase;
                margin: 0 0 6px;
            }
        }
    }

    .footer-info {
        a {
            color: $whiteColor;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .footer-social {
        width: 30%;

        > ul {
            list-style-type: none;
            @extend %clearfix;
            padding: 0;
            margin: 0 0 10px;

            > li {
                float: left;
                margin: 0 14px 14px 0;

                a {
                    display: block;
                    width: 45px;
                    height: 45px;
                    line-height: 41px;
                    text-align: center;
                    overflow: hidden;
                    border: 2px solid currentColor;
                    border-radius: 500em;
                    font-size: 20px;

                    .tt {
                        display: none;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        .footer-cell {
            display: block;
            text-align: center;
        }
        .footer-menu {
            width: auto;
            margin-left: -15px;
            margin-right: -15px;
        }
        .footer-social {
            width: auto;

            > ul {
                > li {
                    float: none;
                    display: inline-block;
                    margin: 0 7px 14px;
                }
            }
        }
    }
}

