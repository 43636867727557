// Helpers
.vertical-align-middle {
    vertical-align: middle;
}

.vertical-align-top {
    vertical-align: top;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.text-align-left {
    text-align: left;
}

// Main styles

.c-title {
    h1 {
        color: $greyColor3;
        font-size: 24px;
        font-weight: 700;
    }
}

.result-list {
    color: $greyColor3;

    .files {
        img:last-of-type {
            position: absolute;
            z-index: -1;
            //-webkit-transition: .3s ease-in-out;
            //transition: .3s ease-in-out;
            opacity: 0;
        }
        img {
            &:hover ~ img {
                //top: 0;
                //left: 100%;
                transform: translate(50%, -50%);
                opacity: 1;
                z-index: 10;
            }
        }
    }

    .download-center-header-items {
        margin-top: 20px;
        background-color: $tableHeaderBackground;
        border-bottom: 3px solid $tableHeaderBorderBottom;
        margin-left: 0;
        margin-right: 0;

        .download-center-header-item {
            font-size: 16px;
            font-weight: 700;
            color: $tableHeaderColor;
            padding-top: 10px;
            padding-bottom: 0;
            hyphens: auto;
        }

        .file-format {
            text-align: center;
        }

        .file-download {
            text-align: center;
        }
    }

    .files {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $tableHeaderBorderBottom;

        .file {
            i {
                color: $mainColor;
            }
            a {
                span {

                    &:hover {
                        color: darken( $redColor, 50% );
                    }
                }
            }


            img.image-preview {
                max-height: 100px;
                max-width: 80px;
            }
        }

        .file-format {
            text-align: center;
        }

        .file-download {
            text-align: center;
            font-size: 20px;
        }
    }

    .download-center-footer {
        padding-top: 20px;
    }

    &.search_products {
        .tab-pane {
            padding-top: 30px;
        }
    }
}


.border-bottom-red {
    border-bottom: 3px solid $tableHeaderBorderBottom !important;
}

.cart-size {
    text-align: center;
}

div.result-list div.download-center-header-items div.download-center-header-item.cart-th-size {
    text-align: center;
}

.cart-th-download {
    text-align: center;
}

.cart-download {
    text-align: center;
}

.cart-th-remove {
    text-align: center;
}

.cart-remove {
    text-align: center;
}

div.result-list div.download-center-header-items div.download-center-header-item.cart-th-select {
    text-align: center;
}

.cart-select {
    text-align: center;
}

.files-small-device-title {
    display: none;
    font-weight: bold;
}


.download-center {

    .url-share-button {
        position: relative;
        top: -10px;
        float: right;
        font-size: 25px;

        &:hover {
            cursor: pointer;
            color: $redColor;
        }
    }

    #result-count {
        font-weight: bold;
    }

    #submit {
        display: none;
    }

    hr {
        margin: 5px 0 4px 0;
    }

    .download-center-header {
        padding: 10px 0 10px 0;
    }

    .customer-filter {
        .selected-values {
            padding: 10px 0 10px 0;

            p {
                margin: 0
            }

            span {
                background: $greyDarkColor1;
                color: $whiteColor;
                border-radius: 0;

                a {
                    color: $whiteColor;

                    svg {
                        fill: $whiteColor;
                    }
                }
            }

            .reset-productfinder {
                background: $redColor;
            }
        }

        form {
            .search-field{
                padding: 0;
            }
        }
    }

    .nr-results,
    .results-per-page {
        padding-top: 6px;

        a {
            color: $blackColor;
        }

        a.active {
            color: $redColor;
            font-weight: bold;
        }
    }

    ul.pagination {
        margin: 0;

        li span {

        }
    }

}

.pagination-container {
    text-align: center;
}

.download-basket {

    .download-basket-results {
        .file.icon i {
            font-size: 22px;
        }
    }
    .download-basket-buttons {
        div:nth-child(1) {
            float: left;
            margin-right: 30px;
        }
    }
    button[disabled] {
        background-color: $lightGreyColor1;
        cursor: no-drop;
    }

}

@media only screen and (max-width: 768px) {
    .result-list {
        .download-center-items {
            .files:nth-child(odd) {
                background-color: $ligthGreyColor;
            }
        }

        .files {
            text-align: center;
            .file-format {
                text-align: center;
            }
        }

        .files-small-device-title {
            display: inline;
        }
    }

    .download-center {
        .nr-results,
        .results-per-page {
            text-align: center;
        }

        .pagination-container {
            .pagination {

                // Default
                > li > a, > li > span {
                    font-size: 20px;
                }
            }
        }
    }

    .download-basket {
        .download-basket-buttons {
            text-align: center;

            div:nth-child(1) {
                float: none;
                margin-right: 0;
            }
        }
    }
}


@media only screen and (max-width: 992px) {
    .download-basket {

        h1 {
            text-align: center;
        }

        .cart-name {
            text-align: center;
        }

        .download-backet-buttons {
            button {
                display: block;
                width: 80%;
                margin: 0 auto;
            }
        }

        .download-basket-results {
            .file {
                margin-top: 10px;

                span {
                    font-weight: 700;
                    color: $headerActiveBg;
                }
            }
        }
    }

    .download-center {

        #search-field {
            float: left;
            height: 44px;
            margin-bottom: 15px;
        }

        #submit {
            float: right;
            display: inline-block;
        }

        .download-center-header {
            .pagination-container {
                float: right;
            }
        }

    }
}

