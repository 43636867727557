.title-big {
    font-weight: 700;
    font-size: 24px;
    color: $greyColor3;
}

.title-small {
    font-size: 14px;
    font-weight: 400;
    color: $greyColor1;
}