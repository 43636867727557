// responsive breakpoints
$break-lg:$screen-lg; // from bootstrap: 1200px
$break-orig-lg: 992px;
$break-md:1015px; // from bootstrap: 992px
$break-orig-md: 768px;
$break-sm:1015px; // from bootstrap: 768px
$break-orig-sm: 576px;
$break-xs:$screen-xs; // from bootstrap: 480px

// dimensions
$headerHeight: 100px;
$headerHeightSm: 70px;
$gutter: 15px;
$gap: 2*$gutter;
$borderRadius: 4px;

// main colours
$ixBlue: #00294D;
$ixTuerkis: #00ABC5;
$ixDarkGrey: #484848;
$ixLightGray: #8B8A8B;
$blueColor1: #2d71c1; // blue color cookie
$whiteColor: #ffffff;  // white
$ligthGreyColor: #F3F3F3;  // light grey
$lightGreyColor1: #c4c4c4; // cookie grey
$lightGreyColor2: #E9ECEE;  // footer grey color
$lightGreyColor3: #999999;  // light grey
$lightGreyColor4: #d1d3d4;  // light grey
$lightGreyColor5: #f0f0f0;  // very light grey
$lightGreyColor6: #cccccc;  // light grey color
$lightGreyColor7: #dddddd;  // light grey color for borders

$blackColor: #000000;  // black
$whiteColor: #ffffff; // white
$orangeColor: #f6821b;  // orange on products page
$redColor: #dd2a1d;  // red color - button background
$greyColor1: #525252;  // grey color medium
$greyColor2: #595959;  // grey color medium
$greyColor3: #57595b;  // grey color medium

$greyDarkColor1: $ixDarkGrey;  // dark grey color

// project colors
$defaultColor: $ixDarkGrey;
$mainColor: $ixTuerkis;  //
$headerColor: $ixBlue;
$headerBg: $whiteColor;  // medium grey (the main grey in the header)
$headerActiveBg: $ixTuerkis;  // dark grey (in search)
$linkColor: $ixBlue;  // ligth blue link color

$ribbonColor: #FF7A59;
$ribbonShadowColor: #E05431;

$menuBackgroundColor: $ligthGreyColor;

// Element Color definitions
$tableHeaderBackground: $ligthGreyColor;
$tableHeaderColor: $blackColor;
$tableHeaderBorderBottom: $whiteColor;

$tabsHeaderBackground: $whiteColor;
$tabsHeaderColor: $ixLightGray;
$tabsHeaderActiveBackground: $ligthGreyColor;
$tabsHeaderActiveColor: $ixTuerkis;
$tabsHeaderBorder: none;
$tabsHeaderBorderRadius: 0;

$headerLinkSize: 20px;

$generalBorderColor: $lightGreyColor4;

%clearfix {
    &:after {
        clear:both;
        display:table;
        content:"";
    }
}
%fontawesome {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
