.cols-same-height {
    padding-bottom: 99999px;
    margin-bottom: -99999px;
}

.flex-grid {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
}

.flex-col {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.text-auto-linebreaker {
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

