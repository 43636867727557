.zone-pre_footer {
    margin-top: 200px;

    .ngl-four_columns {
        background-color: $lightGreyColor2;
        margin-bottom: -60px;
        overflow: hidden;
    }

    .ngl-block {
        text-align: center;
    }

    .ngl-block.ngl-list {
        text-align: left;
        max-width: 300px;
        margin: auto;
    }

    .ngl-vt-rich_text {
        table {
            margin-left: auto;
            margin-right: auto;

            td {
                a {
                    position: relative;
                    top: 8px;
                }
            }
        }
    }

    .ngl-list {
        .title {
            font-size: 14px;
        }
    }
}

footer {
    .footer-info {
        width: 55%;
        float: left;
    }

    .footer-social {
        position: relative;
        width: auto;
        float: right;
        padding-right: 0px;
    }
}

