@media screen and (max-width: 576px) {
	.row-elastic {
		flex-direction: column;
	}
	.row-elastic > div:first-child:not(.comparison-options) {
		background: rgba(240, 243, 244, 0.5);
	}
	.row-elastic div {
		border-bottom: 1px solid whitesmoke;
	}
}
.row.row-elastic {
	display: flex;
	margin: 0;
	&-head {
		margin: 0 -10px;
	}
	.col {
		flex: 1;
		padding-left: 10px;
		padding-right: 10px;
	}
}
#comparison-preview {
	display: block;
	padding: 0;
	line-height: 100px;
	color: #ffffff;
	font-size: x-large;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	float: right;
	margin: 0 10px;
	height: 100px;
	position: relative;
	&:hover #comparison-preview-content {
		display: block;
	}
	a {
		color: #fff;
	}
}
#comparison-preview-content {
	box-shadow: 0 0 10px grey;
	border-radius: 10px;
	padding: 15px;
	display: none;
	position: absolute;
	background-color: #fff;
	right: 0;
	top: 95px;
	transform: translateX(calc(50% - 14px));
	z-index: 999;
	line-height: 1em;
	color: #403e41;
	font-size: 0.6em;
	font-weight: normal;
	min-width: 200px;
	.comparable-product img {
		height: 120px;
		object-fit: contain;
	}
	.arrow {
		width: 40px;
		height: 40px;
		background-color: #fff;
		position: absolute;
		top: -15px;
		left: calc(50% - 22px);
		transform: rotate(-45deg);
	}
	.col-sm-flex {
		max-width: 250px;
		min-width: 230px;
		flex: 1;
		margin: 0 auto;
	}
	.product-bottom-options {
		display: none;
	}
}
.comparable-product {
	background: #f1f3f4;
	padding: 15px;
	margin: 10px 0;
	min-width: 150px;
	.product-image-container {
		img {
			width: 100%;
			margin: auto;
			max-width: 300px;
			display: block;
			border-radius: 4px;
		}
	}
	.product-header {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		margin-bottom: 20px;
		height: 54px;
		overflow: hidden;
		.product-options .delete {
			font-size: 12px;
			i {
				width: 25px;
				line-height: 25px;
				background: #fff;
				text-align: center;
				border-radius: 50%;
				color: #403e41;
			}
		}
	}
	.product-footer {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		padding-top: 10px;
		.product-price {
			margin: 0;
			padding: 10px 0;
			font-weight: bold;
		}
		.product-actions a i {
			color: #403e41;
			width: 25px;
			line-height: 25px;
			text-align: center;
			border-radius: 50%;
			background: #fff;
			font-size: 12px;
			margin: 0 2px;
		}
	}
}
.comparable-product-drop-here {
	padding: 20px;
	text-align: center;
	border: 2px dashed rgb(180, 179, 179);
	margin-bottom: 20px;
	display: none;
}
.product-row-header {
	background: #f1f3f4;
	margin: 20px 0 0;
	.btn {
		width: 100%;
		text-align: left;
	}
}
.product-row {
	border-bottom: 1px solid #f1f3f4;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	.comparison-entry {
		padding: 10px;
	}
	.product-row:nth-child(even) {
		background: rgba(239, 65, 52, 0.1);
	}
}
@media (max-width: 1015px) {
	#comparison-preview {
		position: inherit;
		line-height: 60px;
		.container {
			padding: 0;
		}
	}
	#comparison-preview-content {
		transform: none;
		left: unset;
		right: 0;
		top: 150px;
		max-width: 100%;
		.arrow {
			left: unset !important;
			right: 165px;
		}
		.product-title {
			transform: none;
			left: 0;
			right: 0;
			top: 150px;
		}
		.products-container > .row {
			flex-wrap: wrap;
			max-height: 50vh;
			overflow-y: scroll;
		}
	}
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

.collapsing-icons {
	margin-left: 15px;
}

.collapse-button:hover {
	text-decoration: none;
}

.loader {
	display: inline-block;
	width: 50px;
	height: 50px;
	border: 3px solid #f1f3f4;
	border-top-color: #ef4134;
	border-radius: 50%;
	margin: 10px;
	animation: spin 1.2s cubic-bezier(0.71, 0.32, 0.3, 0.69) infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.comparison-preview-loading {
	text-align: center;
}

.comparison-export-container {
	max-width: 350px;
}

.comparison-add-to-wishlist-select span {
	color: #000 !important;
}

.message_container {
	max-width: calc(100% - 20px);
	margin: auto;
	box-shadow: 0 0 10px rgba(0, 0, 0, .25);
	padding: 20px;
}

.message_container * {
	font-family: sans-serif;
}

.message_container p {
	background: #403e41;
	color: #fff;
	padding: 15px 15px 15px 60px;
	position: relative;
}

.message_container p:before {
	content: ' ';
	display: block;
	position: absolute;
	width: 30px;
	height: 100%;
	background: #ef4134;
	transform: skewX(-10deg);
	top: 0;
	left: 15px;
}

.message_container table {
	width: 100%;
	border-spacing: 0;
}

.message_container table:after {
	content: '';
	display: block;
	border-radius: 5px;
	border: 2px solid #f1f3f4;
}

.message_container tr {
	display: flex;
}

.message_container tr:nth-child(1) {
	font-weight: bold;
	background: #cbd2d6;
	margin-bottom: 0px;
	padding: 10px 0;
}

.message_container tr:nth-child(odd) {
	background: #f1f3f4;
}

.message_container td {
	flex: 1;
	padding: 10px;
}