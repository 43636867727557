/*START facets area*/
.customer-filter .dropdown-menu .btn-close {
    margin: 6px;
    background: none;
    border: 2px solid #cccccc;
    font-weight: bold;
    font-family: 'NettoPro-Bold', Arial, sans-serif;
    display: block;
    text-align: center;
    color: #000;
}

/*END facets values area*/

/*START result header*/


/*STOP result header*/


/*START Selected values area*/

.product-finder-list-wrapper .selected-values p {
    font-size: 16px;
    line-height: 25px;
    color: #3c3c3c;
    font-family: 'NettoPro-Light', Arial, sans-serif;
}

.product-finder-list-wrapper .selected-values p span {
    background: #f5f5f5;
    color: #3c3c3c;
    font-family: 'NettoPro-Bold', Arial, sans-serif;
    font-size: 16px;
    display: inline-block;
    padding: 0 34px 0 10px;
    border-radius: 4px;
    margin-left: 0px;
    height: 30px;
    line-height: 33px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 8px;
}

.product-finder-list-wrapper .selected-values p span:first-child {
    margin-left: 13px;
}

@media only screen and (max-width: 992px) {
    .product-finder-list-wrapper .selected-values p span {
        margin-left: 0;
    }
}

@media only screen and (max-width: 768px) {
    .product-finder-list-wrapper .selected-values p span {
        display: block;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 480px) {
    .product-finder-list-wrapper .selected-values p span {
        max-width: 100%;
    }
}

.product-finder-list-wrapper .selected-values p span.all {
    background: #ddd;
    color: #ffffff;
    padding: 0 36px 0 11px;
}

.product-finder-list-wrapper .selected-values p span a {
    position: absolute;
    top: 50%;
    display: block;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 7px;
    width: 13px;
    height: 13px;
}

.product-finder-list-wrapper .selected-values p span a:hover,
.product-finder-list-wrapper .selected-values p span a:focus {
    text-decoration: none;
}

.product-finder-list-wrapper .selected-values p span a svg {
    width: 13px;
    height: 13px;
    display: block;
}

@media only screen and (max-width: 768px) {
    .product-finder-list-wrapper .selected-values p span + span {
        margin-left: 0;
    }
}

/*END Selected values area*/
